// Generated by Framer (d2b7042)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./N1aTCDs97-0.js";

const enabledGestures = {D_F7hSHuT: {hover: true}};

const cycleOrder = ["D_F7hSHuT"];

const serializationHash = "framer-GHrnQ"

const variantClassNames = {D_F7hSHuT: "framer-v-1apsmjd"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const valuesByLocaleId = {VMMHDo1EA: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, title, width, ...props}) => { return {...props, QsWBTiJtJ: title ?? props.QsWBTiJtJ ?? "filled button"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, QsWBTiJtJ, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "D_F7hSHuT", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1apsmjd", className, classNames)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"D_F7hSHuT"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "rgb(128, 128, 128)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", background: "linear-gradient(88deg, #433e44 0%, #474751 100%)", borderBottomLeftRadius: 44, borderBottomRightRadius: 44, borderTopLeftRadius: 44, borderTopRightRadius: 44, ...style}} variants={{"D_F7hSHuT-hover": {background: "linear-gradient(88deg, hsl(300, 2%, 42%) 0%, hsl(240, 3%, 39%) 100%)"}}} {...addPropertyOverrides({"D_F7hSHuT-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--framer-font-size": "12px", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-e321b03f-d732-43f9-bbf7-842b7ca11fae, rgb(255, 255, 255)))"}}>Chiedi info per noleggio</motion.p></React.Fragment>} className={"framer-131dxp1"} layoutDependency={layoutDependency} layoutId={"HSLPXaT34"} style={{"--extracted-r6o4lv": "var(--token-e321b03f-d732-43f9-bbf7-842b7ca11fae, rgb(255, 255, 255))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={QsWBTiJtJ} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = [".framer-GHrnQ[data-border=\"true\"]::after, .framer-GHrnQ [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-GHrnQ.framer-1shux3d, .framer-GHrnQ .framer-1shux3d { display: block; }", ".framer-GHrnQ.framer-1apsmjd { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 14px; height: 32px; justify-content: center; overflow: hidden; padding: 5px 12px 5px 12px; position: relative; width: auto; will-change: var(--framer-will-change-override, transform); }", ".framer-GHrnQ .framer-131dxp1 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-GHrnQ.framer-1apsmjd { gap: 0px; } .framer-GHrnQ.framer-1apsmjd > * { margin: 0px; margin-left: calc(14px / 2); margin-right: calc(14px / 2); } .framer-GHrnQ.framer-1apsmjd > :first-child { margin-left: 0px; } .framer-GHrnQ.framer-1apsmjd > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 92
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]},"jzP3EtYwH":{"layout":["auto","fixed"]}}}
 * @framerVariables {"QsWBTiJtJ":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerN1aTCDs97: React.ComponentType<Props> = withCSS(Component, css, "framer-GHrnQ") as typeof Component;
export default FramerN1aTCDs97;

FramerN1aTCDs97.displayName = "button-b2b Copy";

FramerN1aTCDs97.defaultProps = {height: 32, width: 92};

addPropertyControls(FramerN1aTCDs97, {QsWBTiJtJ: {defaultValue: "filled button", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerN1aTCDs97, [])